.container{
    align-items: center;
    justify-content: center;
    text-align: center;
}
.navbar{
    padding: 0.8rem;
    background-color:rgb(255, 255, 255);
    border-radius: 10px;
    display: inline-flex;
    margin-top: 1rem;
    justify-content: space-between;
}
.button-group{
    background: transparent;
}
button{
    outline: none;  
    padding: 0px 20px;
    background: transparent;
    border: none;
    font-weight: 500;
    cursor: pointer;;
}

button:hover{
    color:rgb(45, 45, 255);
    font-weight: bold;
    border : rgb(16, 16, 16) solid 1px;
    background-color:beige;
}