.container2{
    display: inline-block;
    border: 1px solid red;
}

.card{
    display: flex;
    margin: 2rem ;
    flex-direction: column;
    background-color:rgb(255, 255, 255);
    padding: 1rem;
    border-radius: 15px;
    gap: 5px;
}
.num{
    border: 1px solid #aaa;
    border-radius: 50%;
    display: inline-block;
    line-height: 22px;
    font-size: 12px;
    height: 25px;
    text-align: center;
    width: 25px;
}
img{
    height: 200px;
    width: 250px;
    align-items: center;
    align-self: center;
    border-radius: 10px;
}
.read{
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 4px;
    margin: 5px 0 20px;
    position: relative;
    text-align: right;
    text-transform: uppercase;  
}
.card-read:after {
    background-color: #b8bddd;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 9px;
    width: 75%;
  }
.order{
    align-self: flex-end;
    margin-top: 1rem;
    padding: 10px 15px;
    border-radius: 10px;
    /* background-color: aqua; */
    font-weight: 600;
    font-size: 10px;
    border: #aaa solid 1px;
    color: #aaa;
}
.menutype{
    text-transform: uppercase;
    font-weight: lighter;
    color: #aaa;
}   
.descrip{
 font-weight: 300;
  line-height: 22px;
  margin: 10px 0;
  color: #aaa;
}
.order:hover{
    color:blue;
}
.inlineflex{
    display: table-row;
    
}

.main-card--cointainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 500px));
    grid-gap: 50px;
    justify-content: space-around;
  }